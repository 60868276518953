import { ProductType } from './Workspace';

export enum BookingState {
    REQUESTED = 'REQUESTED',
    APPROVED = 'APPROVED',
    FINISHED = 'FINISHED',
    CHECKED_IN = 'CHECKED_IN',
    DECLINED = 'DECLINED',
    CANCELLED_REQUEST = 'CANCELLED_REQUEST',
    CANCELLED_BOOKING = 'CANCELLED_BOOKING',
    ONBOARDED = 'ONBOARDED',
}

export interface Booking {
    bookingId: number;
    bookingCode: string;
    name: string;
    email: string;
    productType: ProductType;
    bookingState: BookingState;
    bookingOption: string;
    startAt: number;
    endAt: number;
    workspaceCode: string;
    workspaceId: number;
}

export interface CompanyBooking {
    bookingId: number;
    bookingCode: string;
    name: string;
    email: string;
    productType: ProductType;
    bookingState: BookingState;
    bookingOption: string;
    startAt: number;
    endAt: number;
    city: string;
    credits: number;
    category: string;
    workspaceCode: string;
    workspaceTitle: string;
    workspaceId: number;
}

export interface TopUpPurchase {
    date: number;
    topUp: string;
    amount: number;
    credits: number;
    price: number;
    purchaseMethod: string;
}

export enum SortTab {
    NAME,
    START_AT,
    PRODUCT_TYPE,
    BOOKING_STATE,
}
