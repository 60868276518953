import { ColumnDef } from '@tanstack/react-table';
import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from '@ubique-innovation/react-translations';

import CloseIcon from '../../../images/close.svg';
import { BookingState, CompanyBooking } from '../../../types/Bookings';
import { ProductType, TableType } from '../../../types/Workspace';
import { sortTableBookingsColumn } from '../../../utils/bookings';
import Table from '../../templates/Table';
import * as styles from './CompanyBookingsTab.css';

const getValue = (value: string): string => {
    let adjusted = value;
    if (value.length > 20) {
        adjusted = value.slice(0, 20).concat('...');
    }
    return adjusted;
};

const DetailSection = ({
    keyValue,
    value: val,
}: {
    keyValue: string;
    value: string | undefined | number;
}): React.ReactElement => {
    const { t } = useTranslation();
    const value = typeof val === 'number' ? val.toString() : val;
    return (
        <div className={styles.keyValueWrapper}>
            <div className={styles.key}>{keyValue}</div>
            <div title={value} style={{ fontStyle: `${value === null && 'italic'}` }} className={styles.value}>
                {value === null ? t('bookings.unknown') : getValue(value !== undefined ? value : '')}
            </div>
        </div>
    );
};

const CompanyBookingsTab = ({
    bookings,
    tableType,
}: {
    bookings: CompanyBooking[];
    tableType: TableType;
}): React.ReactElement => {
    const { t } = useTranslation();

    const [showDetailView, setShowDetailView] = useState(false);
    const [bookingDetail, setBookingDetail] = useState<CompanyBooking>(bookings[0]);
    const data: CompanyBooking[] = bookings;

    const productString = {
        [ProductType.FLESK_DESK]: t('flesk.product.fleskDesk.name'),
        [ProductType.FLESK_DESK_DROP_IN]: t('flesk.product.fleskDeskDropIn.name'),
        [ProductType.FLESK_POD]: t('flesk.product.fleskPod.name'),
        [ProductType.FLESK_ROOM_SMALL]: t('flesk.product.fleskRoomSmall.name'),
        [ProductType.FLESK_ROOM_MEDIUM]: t('flesk.product.fleskRoomMedium.name'),
        [ProductType.FLESK_ROOM_LARGE]: t('flesk.product.fleskRoomLarge.name'),
    };

    const stateString = {
        [BookingState.REQUESTED]: t('bookings.status.requested'),
        [BookingState.APPROVED]: t('bookings.status.approved'),
        [BookingState.FINISHED]: t('bookings.status.finished'),
        [BookingState.CHECKED_IN]: t('bookings.status.checked_in'),
        [BookingState.DECLINED]: t('bookings.status.declined'),
        [BookingState.CANCELLED_REQUEST]: t('bookings.status.cancelled_request'),
        [BookingState.CANCELLED_BOOKING]: t('bookings.status.cancelled_booking'),
        [BookingState.ONBOARDED]: t('bookings.status.onboarded'),
    };

    const formatDate = (date: Date): string => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const formatMultiDate = (start: number, end: number): string => {
        const startDate = new Date(start);
        const endDate = new Date(end);

        const startDay = startDate.getDate();
        const endDay = endDate.getDate();
        const startMonth = startDate.getMonth() + 1;
        const endMonth = endDate.getMonth() + 1;
        const year = endDate.getFullYear();
        let fullDate;
        if (startMonth === endMonth) {
            fullDate = `${startDay}.–${endDay}.${startMonth}.${year}`;
        } else {
            fullDate = `${startDay}.${startMonth}.–${endDay}.${endMonth}.${year}`;
        }
        return fullDate;
    };

    const getDate = (start: number, end: number, bookingsOption: string): string => {
        const startDateFormatted = formatDate(new Date(start));
        if (bookingsOption === 'MULTIDAY') {
            // endDate is midnight at the start of the next day
            return formatMultiDate(start, end);
        }
        return startDateFormatted;
    };

    const formatTime = (date: Date): string => {
        const hour = `0${date.getHours()}`.slice(-2);
        const minutes = `0${date.getMinutes()}`.slice(-2);
        return `${hour}:${minutes}`;
    };

    const getTime = (start: number, end: number, bookingsOption: string): string => {
        if (bookingsOption === null) {
            const startTimeFormatted = formatTime(new Date(start));
            if (end === null) {
                return startTimeFormatted;
            }
            const endTimeFormatted = formatTime(new Date(end));
            return `${startTimeFormatted}–${endTimeFormatted}`;
        }
        if (bookingsOption === 'DAYPASS') {
            return t('bookings.option.daypass');
        }
        if (bookingsOption === 'MORNING_PASS') {
            return t('bookings.option.morning_pass');
        }
        if (bookingsOption === 'AFTERNOON_PASS') {
            return t('bookings.option.afternoon_pass');
        }
        return t('bookings.option.multipass');
    };

    const getCategory = (char: string | unknown): string => {
        let category = '';
        switch (char) {
            case 'A':
                category = t('price.category.economy');
                break;
            case 'B':
                category = t('price.category.standard');
                break;
            case 'C':
                category = t('price.category.premium');
                break;
            default:
                category = '';
        }
        return category;
    };

    const escFunction = useCallback(
        (event) => {
            if (event.key === 'Escape') {
                setShowDetailView(false);
            }
        },
        [setShowDetailView],
    );

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);
    }, [escFunction]);

    const columns = React.useMemo<ColumnDef<CompanyBooking>[]>(
        () => [
            {
                header: t('bookings.category.name'),
                accessorKey: 'name',
                cell: (info) => info.getValue(),
            },
            {
                header: t('bookings.category.product'),
                accessorKey: 'productType',
                cell: (info) => productString[info.getValue() as ProductType],
            },

            {
                header: t('space.nav.title.workspace'),
                accessorKey: 'workspaceTitle',
                cell: (info) => info.getValue(),
            },
            {
                header: t('booking.credits'),
                accessorKey: 'credits',
                cell: (info) => (info.getValue() === null ? t('bookings.unknown') : info.getValue()),
            },
            {
                header: t('bookings.category.date'),
                sortingFn: sortTableBookingsColumn,
                accessorFn: (info) => `  ${getDate(info.startAt, info.endAt, info.bookingOption)}
                ${getTime(info.startAt, info.endAt, info.bookingOption)}`,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <>
            {' '}
            {tableType === 'current' && (
                <Table
                    title={t('bookings.open')}
                    sort={t('bookings.category.date')}
                    withDetail
                    setShowDetailView={setShowDetailView}
                    setBookingDetail={setBookingDetail}
                    {...{
                        data,
                        columns,
                    }}
                />
            )}{' '}
            {tableType === 'past' && (
                <Table
                    title={t('bookings.closed')}
                    sort={t('bookings.category.date')}
                    withDetail
                    {...{
                        data,
                        columns,
                    }}
                    setShowDetailView={setShowDetailView}
                />
            )}
            {tableType === 'cancelled' && (
                <>
                    <Table
                        title={t('bookings.cancelled')}
                        sort={t('bookings.category.date')}
                        setShowDetailView={setShowDetailView}
                        setBookingDetail={setBookingDetail}
                        {...{
                            data,
                            columns,
                        }}
                    />
                </>
            )}
            {showDetailView && (
                <div className={styles.detailViewWrapper}>
                    <div className={styles.detail}>
                        <div
                            role="button"
                            tabIndex={0}
                            onClick={() => setShowDetailView(false)}
                            onKeyDown={() => setShowDetailView(false)}
                            className={styles.closeIcon}
                        >
                            <img src={CloseIcon} alt="close-icon" />
                        </div>
                        <div className={styles.subtitle}>{t('booking.bookingNumber')}</div>
                        <div className={styles.bookingCode}>{bookingDetail?.bookingCode}</div>
                        <div className={styles.subtitle}>{t('booking.details')}</div>
                        <DetailSection
                            keyValue={t('bookings.category.product')}
                            value={productString[bookingDetail.productType]}
                        />
                        <DetailSection keyValue={t('bookings.category.name')} value={bookingDetail?.workspaceTitle} />
                        <DetailSection
                            keyValue={t('settings.payment.information.contactcity')}
                            value={bookingDetail?.city}
                        />
                        <DetailSection
                            keyValue={t('booking.date')}
                            value={
                                bookingDetail
                                    ? getDate(bookingDetail.startAt, bookingDetail?.endAt, bookingDetail?.bookingOption)
                                    : ''
                            }
                        />{' '}
                        <DetailSection
                            keyValue={t('booking.time')}
                            value={
                                bookingDetail
                                    ? getTime(bookingDetail.startAt, bookingDetail?.endAt, bookingDetail?.bookingOption)
                                    : ''
                            }
                        />
                        <DetailSection
                            keyValue={t('bookings.category.state')}
                            value={stateString[bookingDetail?.bookingState]}
                        />
                        <DetailSection
                            keyValue={t('booking.category')}
                            value={getCategory(bookingDetail.category.charAt(bookingDetail.category.length - 1))}
                        />
                        <DetailSection keyValue={t('booking.credits')} value={bookingDetail?.credits} />
                        <DetailSection keyValue={t('company.nav.user')} value={bookingDetail?.name} />
                    </div>
                </div>
            )}
        </>
    );
};

export default CompanyBookingsTab;
